import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import constants from "../config/constants";
import resizeImage from "../lib/resize-image";

const apiUrl = `${constants.domain}/api/v1`;
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  if (localStorage.getItem("auth")) {
    const { token } = JSON.parse(localStorage.getItem("auth"));
    options.headers.set("Authorization", `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

const getResizePromises = (data) => {
  const imagePromises = [];
  const parkingPromises = [];
  const galleryPromises = [];

  if (data.image && data.image.length > 0) {
    data.image.forEach((img) => {
      if (img.rawFile) {
        imagePromises.push(resizeImage(img.rawFile));
      }
    });
  } else if (data.image && data.image.rawFile) {
    imagePromises.push(resizeImage(data.image.rawFile));
  }

  if (data.parkings && data.parkings.length > 0) {
    data.parkings.forEach((parking) => {
      if (parking.rawFile) {
        parkingPromises.push(resizeImage(parking.rawFile));
      }
    });
  }

  if (data.gallery && data.gallery.length > 0) {
    data.gallery.forEach((gallery) => {
      if (gallery.rawFile) {
        galleryPromises.push(resizeImage(gallery.rawFile));
      }
    });
  }
  return {
    imagePromises,
    parkingPromises,
    galleryPromises,
  };
};

const getRequestBody = (params) => {
  return new Promise((resolve, reject) => {
    const { data } = params;
    const formData = new FormData();
    if (data.price) {
      data.price = data.price * 100;
    }
    const resizePromises = getResizePromises(data);
    Promise.all(resizePromises.imagePromises)
      .then((images) => {
        if (images && images.length > 1) {
          images.map((img, i) => formData.append(`image${i}`, img));
        } else if (images && images.length === 1) {
          formData.append("image", images[0]);
        }
        Promise.all(resizePromises.parkingPromises)
          .then((parkings) => {
            parkings.forEach((file, i) => {
              formData.append(`parking-image${i}`, file);
            });
            Promise.all(resizePromises.galleryPromises)
              .then((galleries) => {
                galleries.forEach((file, i) => {
                  formData.append(`gallery-image${i}`, file);
                });
                if ([...formData].length > 0) {
                  Object.keys(data).forEach((key) => {
                    if (data[key] instanceof Array) {
                      formData.append(key, JSON.stringify(data[key]));
                    } else {
                      formData.append(key, data[key]);
                    }
                  });
                  resolve(formData);
                } else {
                  resolve(JSON.stringify(params.data));
                }
              })
              .catch(() => reject("Error uploading images"));
          })
          .catch(() => reject("Error uploading images"));
      })
      .catch(() => reject("Error uploading images"));
  });
};

const processResponseData = (response) => {
  if (response && response.length) {
    response.forEach((data) => {
      if (data.price) {
        data.price = data.price / 100;
      }
    });
  } else if (response && response.price) {
    response.price = response.price / 100;
  } else if (!response) {
    response = {};
  }
};

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      const total = parseInt(json.pagination.total, 10);
      processResponseData(json.data);
      return {
        data: json.data,
        total,
      };
    });
  },
  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      processResponseData(json.data);
      return { data: json.data };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      processResponseData(json);
      return { data: json };
    }),

  update: (resource, params) => {
    return getRequestBody(params).then((body) => {
      const url = `${apiUrl}/${resource}/${params.id}`;
      return httpClient(url, {
        method: "PUT",
        body,
      }).then(({ json }) => {
        processResponseData(json);
        return { data: json };
      });
    });
  },

  create: (resource, params) => {
    return getRequestBody(params).then((body) => {
      const url = `${apiUrl}/${resource}`;
      return httpClient(url, {
        method: "POST",
        body,
      }).then(({ json }) => {
        processResponseData(json);
        return { data: json };
      });
    });
  },
  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => {
      processResponseData(json);
      return { data: json };
    }),
  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
    }).then(({ json }) => {
      processResponseData(json);
      return { data: json };
    });
  },
};
