import React, { useState } from "react";
import {
  List,
  Create,
  Edit,
  Datagrid,
  TextField,
  ImageField,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  EditButton,
  ShowButton,
  ImageInput,
  ArrayField,
  SingleFieldList,
} from "react-admin";

export const FoodtruckList = (props) => (
  <List {...props} sort={{ field: "id", order: "DESC" }}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" emptyText="-" />
      <TextField source="description" emptyText="-" />
      <TextField source="location" />
      <ImageField source="imageUrl" label="Image" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const FoodtruckCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="location" />
      <ImageInput source="image" accept="image/*">
        <ImageField source="imageUrl" title="title" />
      </ImageInput>
      <ImageInput source="gallery" accept="image/*" multiple={true}>
        <ImageField source="imageUrl" title="image" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export const FoodtruckEdit = (props) => {
  const [changedImage, setChangedImage] = useState(false);
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="description" />
        <TextInput source="location" />
        <ImageInput
          accept="image/*"
          label="Image"
          source="image"
          onChange={() => setChangedImage(true)}
        >
          <ImageField source="imageUrl" />
        </ImageInput>
        {!changedImage && <ImageField source="imageUrl" label="" />}
        <ImageInput source="gallery" accept="image/*" multiple={true}>
          <ImageField source="imageUrl" title="image" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const FoodtruckShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="location" />
      <ImageField source="imageUrl" label="Image" />
      <ArrayField source="gallery">
        <SingleFieldList>
          <ImageField source="imageUrl" label="Image" />
        </SingleFieldList>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
