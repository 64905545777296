import React from "react";
import {
  List,
  Create,
  Edit,
  Datagrid,
  TextField,
  ImageField,
  SimpleForm,
  Show,
  SimpleShowLayout,
  ImageInput,
} from "react-admin";

export const ImageList = (props) => (
  <List {...props} sort={{ field: "id", order: "DESC" }}>
    <Datagrid>
      <TextField source="id" />
      <ImageField source="imageUrl" label="Image" />
    </Datagrid>
  </List>
);

export const ImageCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ImageInput source="image" accept="image/*" multiple={true}>
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export const ImageEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ImageField source="imageUrl" label="Image" />
    </SimpleForm>
  </Edit>
);

export const ImageShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="alt" />
      <ImageField source="imageUrl" label="Image" />
    </SimpleShowLayout>
  </Show>
);
