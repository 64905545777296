import React from "react";
import {
  List,
  Create,
  Edit,
  Datagrid,
  TextField,
  ImageField,
  SimpleForm,
  Show,
  SimpleShowLayout,
  ImageInput,
  BooleanField,
  TextInput,
  BooleanInput,
  EditButton,
  ShowButton,
} from "react-admin";

export const PartnerList = (props) => (
  <List {...props} sort={{ field: "id", order: "DESC" }}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="url" />
      <ImageField source="partner_media" label="Image" />
      <BooleanField source="enabled" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export const PartnerCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="url" />
      <ImageInput source="image" accept="image/*">
        <ImageField source="partner_media" />
      </ImageInput>
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Create>
);

export const PartnerEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="url" />
      <ImageField source="partner_media" />
      <ImageInput source="image" accept="image/*">
        <ImageField source="partner_media" />
      </ImageInput>
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const PartnerShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="url" />
      <ImageField source="partner_media" label="Image" />
      <BooleanField source="enabled" />
    </SimpleShowLayout>
  </Show>
);
