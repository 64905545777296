import * as React from "react";
import PropTypes from "prop-types";
import { useRecordContext } from "react-admin";

const AudioField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const audio = record ? record[source] : null;
  return (
    <span>
      <audio controls>
        <source src={audio} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </span>
  );
};

AudioField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default AudioField;
