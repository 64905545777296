import React from "react";
import {
  List,
  Create,
  Edit,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  EditButton,
  ShowButton,
  BooleanField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  DateTimeInput,
  DateField,
} from "react-admin";

export const LocationsList = (props) => (
  <List {...props}>
    <Datagrid>
      <ReferenceField label="User email" source="user_id" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="device_uuid" />
      <TextField source="device_platform" />
      <TextField source="latitude" />
      <TextField source="longitude" />
      <BooleanField source="enabled" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const LocationCreate = (props) => (
  <Create {...props}>
    <SimpleForm {...props}>
      <ReferenceInput label="User email" source="user_id" reference="users">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <TextInput source="device_uuid" />
      <TextInput source="device_platform" />
      <DateTimeInput source="time" />
      <TextInput source="latitude" />
      <TextInput source="longitude" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Create>
);

export const LocationEdit = (props) => (
  <Edit {...props}>
    <SimpleForm {...props}>
      <ReferenceInput label="User email" source="user_id" reference="users">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <TextInput source="device_uuid" />
      <TextInput source="device_platform" />
      <DateTimeInput source="time" />
      <TextInput source="latitude" />
      <TextInput source="longitude" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const LocationShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
    <ReferenceField label="User email" source="user_id" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="device_uuid" />
      <TextField source="device_platform" />
      <DateField source="time" showTime={true}/>
      <TextField source="latitude" />
      <TextField source="longitude" />
      <BooleanField source="enabled" />
    </SimpleShowLayout>
  </Show>
);
