import React from "react";
import {
  List,
  Create,
  Edit,
  Datagrid,
  TextField,
  ImageField,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  EditButton,
  ShowButton,
  DateField,
  NumberInput,
  SelectInput,
  DateTimeInput,
  ImageInput,
  BooleanField,
  BooleanInput,
  ArrayField,
  SingleFieldList,
  SelectField,
} from "react-admin";
import AudioField from "../AudioField/AudioField";
import AudioInput from "../AudioInput/AudioInput";
import PriceField from "../PriceField/PriceField";
/*
 place,
      position,
      hasFees,
      opening,
      artistWebsiteUrl,
      placeMapUrl,*/
export const EventList = (props) => (
  <List {...props} sort={{ field: "id", order: "DESC" }}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="description" />
      <ImageField source="imageUrl" title="Image" />
      <AudioField source="audioUrl" />
      <PriceField source="price" />
      <BooleanField source="hasFees" />
      <DateField source="date" showTime />
      <BooleanField source="published" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const EventCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="description" multiline />
      <ImageInput source="image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <AudioInput source="audioUrl" />
      <NumberInput source="price" />
      <BooleanInput source="hasFees" />
      <TextInput source="ticketUrl" />
      <TextInput source="place" />
      <TextInput source="opening" />
      <TextInput source="position" />
      <SelectInput
        source="kind"
        choices={[
          { id: "1", name: "Castillo" },
          { id: "2", name: "Loma" },
          { id: "3", name: "Castillo y Loma" },
        ]}
      />
      <ImageInput source="parkings" accept="image/*" multiple={true}>
        <ImageField source="imageUrl" title="image" />
      </ImageInput>
      <TextInput source="artistWebsiteUrl" />
      <DateTimeInput source="date" />
      <BooleanInput source="published" />
    </SimpleForm>
  </Create>
);

export const EventEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="description" multiline />
      <ImageField source="imageUrl" title="Image" />
      <ImageInput source="image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <AudioInput source="audioUrl" />
      <NumberInput source="price" min={1} />
      <BooleanInput source="hasFees" />
      <TextInput source="ticketUrl" />
      <TextInput source="place" />
      <TextInput source="opening" />
      <TextInput source="position" />
      <SelectInput
        source="kind"
        choices={[
          { id: "1", name: "Castillo" },
          { id: "2", name: "Loma" },
          { id: "3", name: "Castillo y Loma" },
        ]}
      />
      <TextInput source="artistWebsiteUrl" />
      <ImageInput source="parkings" accept="image/*" multiple={true}>
        <ImageField source="imageUrl" title="image" />
      </ImageInput>
      <DateTimeInput source="date" />
      <BooleanInput source="published" />
    </SimpleForm>
  </Edit>
);

export const EventShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="description" />
      <ImageField source="imageUrl" />
      <AudioField source="audioUrl" />
      <TextField source="price" />
      <BooleanField source="hasFees" />
      <TextField source="place" />
      <ArrayField source="parkings">
        <SingleFieldList>
          <ImageField source="imageUrl" label="Image" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="opening" />
      <TextField source="position" />
      <TextField source="artistWebsiteUrl" />
      <SelectField source="kind" choices={[
          { id: "1", name: "Castillo" },
          { id: "2", name: "Loma" },
          { id: "3", name: "Castillo y Loma" },
        ]} />
      <TextField source="kind" />
      <DateField source="date" />
    </SimpleShowLayout>
  </Show>
);
