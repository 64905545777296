const processFile = (dataURL, file) => {
  return new Promise((resolve, reject) => {
    const maxWidth = 2500;
    const maxHeight = 2500;

    const image = new Image();
    image.src = dataURL;

    image.onload = function () {
      const width = image.width;
      const height = image.height;
      const shouldResize = width > maxWidth || height > maxHeight;

      if (!shouldResize) {
        return resolve(file);
      }

      let newWidth;
      let newHeight;

      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }

      const canvas = document.createElement("canvas");

      canvas.width = newWidth;
      canvas.height = newHeight;

      const context = canvas.getContext("2d");

      context.drawImage(this, 0, 0, newWidth, newHeight);

      canvas.toBlob((blob) => {
        const resizedFile = new File([blob], `${file.name}`, {
          type: blob.type,
        });
        resolve(resizedFile);
      }, 'image/jpeg');
    };

    image.onerror = function () {
      reject("There was an error processing your file!");
    };
  });
};

export default (file) => {
  return new Promise((resolve, reject) => {
    if (window.File && window.FileReader && window.FormData) {
      if (file) {
        if (/^image\//i.test(file.type)) {
          const reader = new FileReader();

          reader.onloadend = function () {
            processFile(reader.result, file)
              .then((resizedFile) => {
                resolve(resizedFile);
              })
              .catch((error) => {
                reject(error);
              });
          };

          reader.onerror = function () {
            reject("There was an error reading the file!");
          };

          reader.readAsDataURL(file);
        } else {
          reject("Not a valid image!");
        }
      }
    } else {
      reject("File upload is not supported!");
    }
  });
};
