import React from "react";
import {
  List,
  Create,
  Edit,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  PasswordInput,
  SelectInput,
  Show,
  SimpleShowLayout,
  EditButton,
  ShowButton,
  SelectField,
  BooleanField,
  BooleanInput,
  DateField,
  DateTimeInput,
} from "react-admin";

const roles = [
  { id: "0740", name: "Usuario" },
  { id: "4740", name: "Trabajador" },
  { id: "2740", name: "VIPS y Promotores" },
  { id: "superAdmin", name: "superAdmin" },
  { id: "admin", name: "admin" },
];

const rolesList = [
  { id: "740", name: "Usuario" },
  { id: "0740", name: "Usuario" },
  { id: "4740", name: "Trabajador" },
  { id: "2740", name: "VIPS y Promotores" },
  { id: "superAdmin", name: "superAdmin" },
  { id: "admin", name: "admin" },
];

const createdMethods = [
  { id: "A", name: "Invitados" },
  { id: "M", name: "Alta App" },
  { id: "W", name: "Alta Web" },
];

const userFilters = [<TextInput label="Search" source="email" alwaysOn />];

export const UsersList = (props) => (
  <List {...props} filters={userFilters}>
    <Datagrid>
      <TextField source="email" />
      <TextField source="first_name" />
      <SelectField source="role" choices={rolesList} />
      <BooleanField source="enabled" />
      <DateField source="created_at" showTime={true} />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm {...props}>
      <TextInput source="email" />
      <PasswordInput source="password" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <SelectInput source="role" choices={roles} />
      <TextInput source="nif" />
      <TextInput source="address[0].street" label="Street" />
      <TextInput source="address[0].town" label="Town" />
      <TextInput source="address[0].region" label="Region" />
      <TextInput source="address[0].postcode" label="PostCode" />
      <TextInput source="address[0].country" label="Country" />
      <TextInput source="phone" />
      <DateTimeInput source="birthday" />
      <SelectInput source="created_method" choices={createdMethods} />
      <BooleanInput source="registered" label="Registered in Fuengirola"/>
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Create>
);

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm {...props}>
      <TextField source="email" />
      <PasswordInput source="password" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <SelectInput source="role" choices={roles} />
      <TextInput source="nif" />
      <TextInput source="address[0].street" label="Street" />
      <TextInput source="address[0].town" label="Town" />
      <TextInput source="address[0].region" label="Region" />
      <TextInput source="address[0].postcode" label="PostCode" />
      <TextInput source="address[0].country" label="Country" />
      <TextInput source="phone" />
      <DateTimeInput source="birthday" />
      <SelectInput source="created_method" choices={createdMethods} />
      <BooleanInput source="registered" label="Registered in Fuengirola"/>
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <SelectField source="role" choices={roles} />
      <TextField source="nif" />
      <TextField source="phone" />
      <TextField source="address.street" />
      <TextField source="address.town" />
      <TextField source="address.region" />
      <TextField source="address.postalcode" />
      <TextField source="address.country" />
      <TextField source="birthday" />
      <TextField source="created_method" />
      <BooleanField source="registered" label="Registered in Fuengirola"/>
      <BooleanField source="enabled" />
    </SimpleShowLayout>
  </Show>
);
