import React from "react";
import {
  List,
  Create,
  Edit,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  EditButton,
  ShowButton,
  BooleanField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
} from "react-admin";

const userFilters = [<TextInput label="Search" source="email" alwaysOn />];

export const TicketsList = (props) => (
  <List {...props} filters={userFilters}>
    <Datagrid>
      <ReferenceField label="User email" source="user_id" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField label="Event" source="event_id" reference="events">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="ticket" />
      <TextField source="zone" />
      <TextField source="row" />
      <TextField source="seat" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const TicketCreate = (props) => (
  <Create {...props}>
    <SimpleForm {...props}>
      <ReferenceInput label="User email" source="user_id" reference="users">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput label="Event" source="event_id" reference="events">
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <TextInput source="schedule_id" />
      <TextInput source="ticket" />
      <TextInput source="zone" />
      <TextInput source="row" />
      <TextInput source="seat" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Create>
);

export const TicketEdit = (props) => (
  <Edit {...props}>
    <SimpleForm {...props}>
      <ReferenceInput label="User email" source="user_id" reference="users">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput label="Event" source="event_id" reference="events">
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <TextInput source="schedule_id" />
      <TextInput source="ticket" />
      <TextInput source="zone" />
      <TextInput source="row" />
      <TextInput source="seat" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const TicketShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField label="User email" source="user_id" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField label="Event" source="event_id" reference="events">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="schedule_id" />
      <TextField source="ticket" />
      <TextField source="zone" />
      <TextField source="row" />
      <TextField source="seat" />
      <BooleanField source="enabled" />
    </SimpleShowLayout>
  </Show>
);
