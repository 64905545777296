import React from "react";
import {
  List,
  Create,
  Edit,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  EditButton,
  ShowButton,
  BooleanField,
  BooleanInput,
} from "react-admin";

export const FaqsList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="question" />
      <TextField source="answer" />
      <BooleanField source="enabled" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const FaqCreate = (props) => (
  <Create {...props}>
    <SimpleForm {...props}>
      <TextInput source="question" />
      <TextInput source="answer" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Create>
);

export const FaqEdit = (props) => (
  <Edit {...props}>
    <SimpleForm {...props}>
      <TextInput source="question" />
      <TextInput source="answer" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const FaqShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="question" />
      <TextField source="answer" />
      <BooleanField source="enabled" />
    </SimpleShowLayout>
  </Show>
);
