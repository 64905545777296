import React, { useState, useRef } from "react";
import { fetchUtils } from "react-admin";
import constants from "../../config/constants";
import "./AudioInput.css";
import { useField } from "react-final-form";
let autocompleteTimeout = null;

const AudioInput = (params) => {
  const [val, setVal] = useState(params.record[params.source]);
  const [suggestions, setSuggestions] = useState([]);
  const audioRef = useRef();
  const onInputChange = (e) => {
    if (autocompleteTimeout) {
      clearTimeout(autocompleteTimeout);
    }
    setVal(e.target.value);
    setSuggestions([]);
    if (e.target.value.length > 2) {
      //const apiUrl = `http://139.59.219.119/tapi.php?s=${e.target.value}`;
      const apiUrl = `${constants.domain}/api/1/songs?s=${e.target.value}`;
      autocompleteTimeout = setTimeout(() => {
        fetchUtils.fetchJson(apiUrl).then(({ json }) => {
          setSuggestions(json.data || []);
        });
      }, 800);
    }
    onChange(e);
  };

  const onBlur = () => {
    setTimeout(() => setSuggestions([]), 250);
  };

  const onSelectSuggestion = (e, suggestion) => {
    setVal(`${suggestion.artist.name} - ${suggestion.title}`);
    setSuggestions([]);
    onChange({ target: { value: suggestion.preview } });
    audioRef.current.src = suggestion.preview;
  };
  const {
    input: { onChange, value },
    meta: { error },
  } = useField(params.source);
  return (
    <>
      <span>
        <div className="MuiFormControl-root MuiTextField-root RaFormInput-input-59 MuiFormControl-marginDense">
          <label
            className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled"
            data-shrink="true"
            htmlFor="audioUrl"
            id="audioUrl-label"
          >
            <span>Canción</span>
          </label>
          <div className="audio-container MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense">
            <input
              aria-invalid="false"
              aria-describedby="audioUrl-helper-text"
              id="canción"
              name="audioUrl"
              type="text"
              className="MuiInputBase-input MuiFilledInput-input MuiInputBase-inputMarginDense MuiFilledInput-inputMarginDense"
              onChange={onInputChange}
              onBlur={onBlur}
              value={value}
              autoComplete="off"
            />
            {suggestions && suggestions.length > 0 && (
              <ul className="suggestions">
                {suggestions.map((suggestion) => (
                  <li
                    className="suggestions__item"
                    key={suggestion.id}
                    onClick={(e) => onSelectSuggestion(e, suggestion)}
                  >
                    <img
                      className="suggestions__img"
                      alt="album cover"
                      src={suggestion.album.cover_small}
                    />
                    <span className="suggestions__text">
                      {suggestion.artist.name} - {suggestion.title}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <p
            className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled MuiFormHelperText-marginDense"
            id="audioUrl-helper-text"
          >
            <span>​{error}</span>
          </p>
        </div>
      </span>
      <span className="audio_player">
        <audio controls ref={audioRef}>
          <source src={val} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      </span>
    </>
  );
};

export default AudioInput;
