import * as React from "react";
import PropTypes from "prop-types";
import { useRecordContext } from "react-admin";

const PriceField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const price = record[source];
  return (
    <span>{parseFloat(price < 100 ? price : price / 100).toFixed(2)}€</span>
  );
};

PriceField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default PriceField;
